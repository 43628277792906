/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../application.scss'

let keyframes = ['Condo near Orlando, FL',
    'Condo near Orlando, F',
    'Condo near Orlando, ',
    'Condo near Orlando,',
    'Condo near Orlando',
    'Condo near Orland',
    'Condo near Orlan',
    'Condo near Orla',
    'Condo near Orl',
    'Condo near Or',
    'Condo near O',
    'Condo near ',
    'Condo near',
    'Condo nea',
    'Condo ne',
    'Condo n',
    'Condo ',
    'Condo',
    'Cond',
    'Con',
    'Co',
    'C',
    '',
    'V',
    'Va',
    'Vac',
    'Vaca',
    'Vacat',
    'Vacati',
    'Vacatio',
    'Vacation',
    'Vacation ',
    'Vacation V',
    'Vacation Vi',
    'Vacation Vil',
    'Vacation Vill',
    'Vacation Villa',
    'Vacation Villag',
    'Vacation Village',
    'Vacation Villag',
    'Vacation Villa',
    'Vacation Vill',
    'Vacation Vil',
    'Vacation Vi',
    'Vacation V',
    'Vacation ',
    'Vacation',
    'Vacatio',
    'Vacati',
    'Vacat',
    'Vaca',
    'Vac',
    'Va',
    'V',
    '',
    '#',
    '#1',
    '#13',
    '#135',
    '#1353',
    '#13532',
    '#1353',
    '#135',
    '#13',
    '#1',
    '#',
    '',
    'n',
    'ne',
    'nea',
    'near',
    'near ',
    'near D',
    'near Di',
    'near Dis',
    'near Disn',
    'near Disne',
    'near Disney',
    'near Disne',
    'near Disn',
    'near Dis',
    'near Di',
    'near D',
    'near ',
    'near',
    'nea',
    'ne',
    'n',
    '',
    'S',
    'St',
    'Sto',
    'Stor',
    'Storm',
    'Stormy',
    'Stormy ',
    'Stormy P',
    'Stormy Po',
    'Stormy Poi',
    'Stormy Poin',
    'Stormy Point',
    'Stormy Poin',
    'Stormy Poi',
    'Stormy Po',
    'Stormy P',
    'Stormy ',
    'Stormy',
    'Storm',
    'Stor',
    'Sto',
    'St',
    'S',
    '',
    'C',
    'Co',
    'Con',
    'Cond',
    'Condo',
    'Condo ',
    'Condo n',
    'Condo ne',
    'Condo nea',
    'Condo near',
    'Condo near ',
    'Condo near O',
    'Condo near Or',
    'Condo near Orl',
    'Condo near Orla',
    'Condo near Orlan',
    'Condo near Orland',
    'Condo near Orlando',
    'Condo near Orlando,',
    'Condo near Orlando, ',
    'Condo near Orlando, F',
    'Condo near Orlando, FL'];


function placeholder_type(input, i = 0, add = true) {
    let should_add = keyframes[(i + 1) % keyframes.length].length > keyframes[i].length;
    input.setAttribute('placeholder', keyframes[i]);

    let delay = add && !should_add ? 2500 : should_add ? 110 + 60 * Math.random() : 65;
    setTimeout(function () {
        placeholder_type(input, (i + 1) % keyframes.length, should_add);
    }, delay);
}

let inputs = document.getElementsByClassName('search-box');
if (inputs.length !== 0) {
    for (let i = 0; i < inputs.length; i++) {
        placeholder_type(inputs[i])
    }
}
